import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import LocaleButton from './LocaleButton';
import { NavbarColor } from '../../lib/types';
import { getStringData, getUploadFileUrl } from '../../lib/strapi';
import type { ApiNavbarNavbar, ButtonsButton } from '@eva/cms-types';

interface Props {
  data: ApiNavbarNavbar['attributes'];
  color: NavbarColor;
  locale?: string;
  availibleLocales: LocaleLocale[];
}

function MobileNavbar(props: Props) {
  const [open, setOpen] = useState(false);

  const { url: logoUrl, alt: logoAlt } = getUploadFileUrl(props.data.logo);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'auto';
  }, [open]);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="desktop:hidden col-[span_1/4] ml-auto"
      >
        <img
          src={`/${
            props.color === NavbarColor.brand ? 'burger' : 'burger-dark'
          }.svg`}
        />
      </button>
      <CSSTransition in={open} timeout={200} classNames="menu" unmountOnExit>
        <div className="fixed min-h-screen min-h-[100dvh] h-full w-screen top-0 left-0 z-50 bg-dark overflow-y-auto">
          <div className="p-6 pb-24 h-full">
            <div className="flex justify-between">
              <img
                src={logoUrl}
                alt={logoAlt}
                loading="lazy"
                decoding="async"
                className="w-[5.5rem] h-[2rem] justify-self-start"
              />
              <button onClick={() => setOpen(false)}>
                <img src="/cross.svg" alt="" />
              </button>
            </div>
            <div className="flex flex-col justify-around h-full">
              <div className="flex flex-col gap-12 justify-center items-center">
                {(
                  props.data
                    .navigation as unknown as ButtonsButton['attributes'][]
                ).map((item, index) => {
                  const { href, text } = getStringData(item);
                  return (
                    <a
                      key={index}
                      href={href}
                      onClick={() => setOpen(false)}
                      className={`text-h4 whitespace-nowrap text-white`}
                    >
                      {text}
                    </a>
                  );
                })}
              </div>
              <div className="flex flex-col gap-12 justify-center items-center">
                <LocaleButton
                  locale={props.locale}
                  availibleLocales={props.availibleLocales}
                />
              </div>
              <div className="flex flex-col gap-12 justify-center items-center">
                {(
                  props.data.action as unknown as ButtonsButton['attributes'][]
                ).map((item, index) => {
                  const { text, href } = getStringData(item);
                  return (
                    <a
                      key={index}
                      href={href}
                      onClick={() => setOpen(false)}
                      className={`text-h4 whitespace-nowrap text-white`}
                    >
                      {text}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

export default MobileNavbar;
